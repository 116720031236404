import { Route, Routes } from "react-router-dom"
import { ActividadesYProgramas } from "./ActividadesYProgramas"
import { Exportar } from "./Exportar"
import { Otros } from "./Otros"
import { TablasGenerales } from "./TablasGenerales"
import { Usuarios } from "./Usuarios"
import { FormatoCurso } from "../componentesC/formatoCursos/FormatoCurso" 
import { Indicadores } from  "./Indicadores"
import FormatosMEN from "../registro/FormatosMEN/FormatosMEN"
import { Trimester } from "../admin/Evaluaciones/reportes/Trimester/Trimester"
import { ListaDocentes } from "../admin/Evaluaciones/reportes/Trimester/ListaDocentes"
import { ListaEventos } from "../admin/Evaluaciones/reportes/Trimester/ListaEventos"
import { ListaCoordinadores } from "../admin/Evaluaciones/reportes/Trimester/ListaCoordinadores"
import { OfertasExternas } from "./ofertasExternas/OfertasExternas"
import { EstudianteOfertaExterna } from "./estudiantesOfertaExterna/EstudiantesOfertaExterna"
import Graficas from "./Graficas/Graficas"

const Reportes=()=>{

    return(
        <Routes>
            <Route path="usuarios" element={<Usuarios/>} />
            <Route path="activiades_y_programas" element={<ActividadesYProgramas/>}/>
            <Route path="otros" element={<Otros/>}/>
            <Route path="exportar" element={<Exportar/>}/>
            <Route path="actividades_tercerizadas" element={<OfertasExternas/>}/>
            <Route path="actividades_tercerizadas/estudiantesOfertaExterna/:id" element={<EstudianteOfertaExterna/>}/>
            <Route path="indicadores" element={<Indicadores/>}/>
            <Route path="exportar/:idoferta/formatoCurso" element={<FormatoCurso/>}/>
            <Route path="tablas_generales" element={<TablasGenerales/>}/>
            <Route path="formatos_men" element={<FormatosMEN />} ></Route>
            <Route path="reportes_autoevaluacion" element={<Trimester/>}/>
            <Route path="reportes_autoevaluacion/docentes" element={<ListaDocentes/>}/>
            <Route path="reportes_autoevaluacion/eventos" element={<ListaEventos/>}/>
            <Route path="reportes_autoevaluacion/coordinadores" element={<ListaCoordinadores/>}/>
            <Route path="graficas" element={<Graficas/>}/>
        </Routes>
    )
}

export {Reportes}