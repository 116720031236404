import React, { lazy, Suspense, useEffect, useState,  } from 'react';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json'
import './App.css';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { DashboardHome } from './components/outlet/DashboardHome/DashboardHome';
import { Universidades } from './components/outlet/universidad/Universidades';
import { ProcesosContainer } from './components/outlet/procesos/ProcesosContainer';
import { Inicio } from './pages/inicio/Inicio';
import { InscripcionAspirante } from './zreciclaje/InscripcionAspirante/InscripcionAspirante';
import RequireAuth from './pages/Login/RequireAuth'
import Noticias from './pages/Noticias/Noticias';
import { DynamicFormContainer } from './components/DynamicForm/DynamicFormContainer';
import { AperturaContainer } from './components/outlet/apertura/AperturaContainer';
import { SignUpContainer } from './pages/Registro/SignUpContainer';
import { UserProfileContainer } from './pages/UserProfile/UserProfileContainer';
import Consentimiento1 from './pages/Consentimientos/Consentimiento1';
import Consentimiento2 from './pages/Consentimientos/Consentimiento2';
import HabeasContainer from './pages/Consentimientos/HabeasContainer';
import { Offer } from './pages/Offer/Offer';
import { LoginContainer } from './pages/Login/LoginContainer';


import { Navigate, Route, Routes } from 'react-router-dom';
import { Docente } from './modules/docente/Docente';
import { Registro } from './modules/registro/Registro';
import notfoundimage from './assets/img/decoration_images/undraw_no_data_re_kwbl.svg'
import { Estudiante } from './modules/estudiante/Estudiante';
import { OfertaAcademica } from './modules/admin/ofertaAcademica/OfertaAcademica';
import { OfertaAcademicaPage } from './pages/Ofertas/OfertaAcademicaPage'

import { Usuarios } from './modules/admin/usuarios/Usuarios';
import { ParametricasGenerales } from './modules/admin/parametricasGenerales/ParametricasGenerales';
import { Evaluaciones } from './modules/admin/Evaluaciones/Evaluaciones';
import { Coordinador } from './modules/coordinador/Coordinador';
import { RecoverPassword } from './components/RecoverPassword/RecoverPassword';
import { Admision } from './modules/admison/Admision';
import { Convocatoria } from './modules/admin/convocatoria/Convocatoria';
import { Seguridad } from './modules/admin/seguridad/Seguridad';
import { InvestigacionAccidentes } from './modules/admin/investigacionAccidentes/InvestigacionAccidentes';
import { AdmisionMatricula } from './modules/admin/admisionMatricula/AdmisionMatricula';
import { Aspirante } from './modules/aspirante/Aspirante';
import { PlaneacionAcademica } from './modules/admin/planeacionAcademica/PlaneacionAcademica';
import { Financiero } from './modules/admin/financiero/Financiero';
import { useSelector } from 'react-redux';
import { GestionDocentes } from './modules/gestiondocentes/GestionDocentes';
import { Bienestar } from './modules/bienestar/Bienestar';
import { Reportes } from './modules/reportes/Reportes';
import { Calendario } from './modules/calendario/Calendario';
import { CoordinadorAcademico } from './modules/coordinadorAcademico/CoordinadorAcademico';
import { ActividadesTercerizadas } from './modules/activiadesTercerizadas/ActividadesTercerizadas';
import { ActividadesTerceros } from './modules/actividadesTerceros/ActividadesTerceros';
import { ApoyoGestion } from './modules/apoyoGestion/ApoyoGestion';
import {GestionEgresados} from './modules/gestionEgresados/GestionEgresados';
import { Egresado } from './modules/egresado/Egresado';
import GradosCertificados from './modules/GradosCertificados/GradosCertificados';
import AutoservicioGrado from './modules/AutoservicioGrado/AutoservicioGrado';
import Portafolio from './pages/PortafolioAcademico/Portafolio';
import Solicitudes from './modules/Solicitudes/Solicitudes';
import GestionarSolicitudes from './modules/GestionarSolicitudes/GestionarSolicitudes';
import Empresas from './modules/Empresas/Empresas';

import { useNavigate } from 'react-router-dom';
import { alertaSinBoton } from './helpers/alertas';
import axios from 'axios';
import { ACCESS_TOKEN, BASE_URL } from './services/api/config';
import { Auditoria } from './modules/Auditoria/Auditoria';
import Solicitud from './modules/Solicitudes/Solicitud';
import MisSolicitudes from './modules/Solicitudes/MisSolicitudes';
import PerfilUsuario from './modules/componentesC/PerfilUsuario/PerfilUsuario';
import { ValidaCertificado } from './pages/ValidaCertificado/ValidaCertificado';
import PlaneacionEducacion from './modules/PlaneacionEducacion/PlaneacionEducacion';
import ActualizarDatosAspirante from './components/outlet/aspiranteModulo/Inscripciones/ActualizarDatosAspirante';
import ConsultaAcademica from './modules/ConsultaAcademica/ConsultaAcademica';
import ParametricasSAA from './modules/ParametricasSAA/ParametricasSAA';
import AutoridadAeronautica from './modules/CursosPieso/CursosPieso';
import CursosPieso from './modules/CursosPieso/CursosPieso';
import ActividadesInspeccion from './modules/ActividadesInspeccion/ActividadesInspeccion';
import { PIC } from './modules/PIC/PIC';
import Extension from './modules/Extension/Extension';
import { Internacionalizacion } from './modules/Internacionalizacion/Internacionalizacion';
import PlanEstabilizacion from './modules/PlanEstabilizacion/PlanEstabilizacion';
import GestionEducacion from './modules/GestidonEducacion/GestionEducacion';
import Alertas from './modules/Alertas/Alertas';

function App()  {
  const currentMenu = useSelector(state=>state.user.currentMenu)
  const [usuario, setUsuario] = useState(null);
  const [cargandoUsuario, setCargandoUsuario] = useState(true);
  const ls = localStorage;
  const getToken = ls.getItem(ACCESS_TOKEN);
  const navigate = useNavigate();
  const isProduction = process.env.NODE_ENV === 'production';
  const version = packageInfo.version;

  useEffect(()=>{
    
    async function cargaUser() {
      try {
        const { data: user} = await axios.get(`${BASE_URL}/getUsuario?token=${getToken}`);
        setUsuario(user);
        setCargandoUsuario(false);
        
        if(!user || user.exception || user.message || user === null || user === '' || user.respuesta === "error, 404") {
  
          setTimeout(() => {
            ls.removeItem('access_token')
            ls.removeItem('usuario')
            ls.removeItem('idRol')
            ls.removeItem('roles')
            ls.removeItem('menu')
            ls.removeItem('dimensiones')
            ls.removeItem('currentRol')
            alertaSinBoton('Su sesión ha expirado','success','center',2000)
            setTimeout(() => {
                navigate("/inicio", { replace:true }
            )}, 300);
          }, 4000);
        }
        /* console.log(user); */
      } catch (error) {

        console.log(error)
        if(getToken === null) {
          setTimeout(() => {
            ls.removeItem('access_token')
            ls.removeItem('usuario')
            ls.removeItem('idRol')
            ls.removeItem('roles')
            ls.removeItem('menu')
            ls.removeItem('dimensiones')
            ls.removeItem('currentRol')
            alertaSinBoton('Su sesión ha expirado','success','center',2000)
            setTimeout(() => {
                navigate("/inicio", { replace:true }
            )}, 300);
          }, 4000);
        }
      }
    }
    
    if(getToken !== null) {
      cargaUser();
    }
    
  },[])
  return (
      <CacheBuster
        currentVersion={version}
        isEnabled={isProduction}
        isVerboseMode={false}
        loadingComponent={<LoginContainer />}
        metaFileDirectory={'.'}
      >
      <div className='wrapper'>
        <Suspense fallback={<p> Cargando...</p>}>
        <Routes>
          {/* <Route path="/" element={<Navigate to="/inicio" replace />}/> */}
          <Route path="/inicio" element={<Inicio /> } replace /> 
          <Route path="/inicio/:redireccion" element={<Inicio /> } replace /> 
          <Route path="/oferta" element={<OfertaAcademicaPage />} replace/>
          <Route path="/solicitudes" element={<Solicitudes />} replace/>
          <Route path="/solicitudes/solicitud" element={<Solicitud />} replace/>
          <Route path="/solicitudes/mis_solicitudes" element={<MisSolicitudes />} replace/>

          <Route path="/oferta/:idDimension/cursos" element={<Portafolio />} replace/>
          <Route path="/login" element={<LoginContainer />} />
          <Route path="/oferta/:idDimension/cursos/oferta-detalle/:idOffer" element={<Offer />} />     
          <Route path="inscripcion-aspirante" element={<InscripcionAspirante />} />
          <Route path="/restore-password" element={<RecoverPassword />} />
          <Route path="/perfil/*" element={<PerfilUsuario />} replace/>
          <Route path="/noticias" element={<Noticias />} />
          <Route path="/consentimiento1" element={<Consentimiento1 />} />
          <Route path="/consentimiento2" element={<Consentimiento2 />} />
          <Route path="/habeas" element={<HabeasContainer />} />
          <Route path="/sign-up" element={<SignUpContainer />} />
          <Route path="/validacionCertificados/:valor" element={<ValidaCertificado/>} />
          <Route path="/dashboard/*" element={
            <div className='dashboard'>
              <RequireAuth >
                <Dashboard></Dashboard>
              </RequireAuth>
            </div>
          } >
            <Route path="inicio" element={<DashboardHome />} />
            <Route path="generalidades/Universidad" element={<Universidades />} />
            <Route path="perfil" element={<UserProfileContainer />} />
            <Route  path="parametricas/*" element = {<ParametricasGenerales/>} />
            <Route path="consulta_academica/*" element={<ConsultaAcademica />} />
            <Route path="parametricas_saa/*" element={<ParametricasSAA/> } /> 
            <Route path="cursos_pieso/*" element={<CursosPieso/> } />
            <Route path="actividades_de_inspeccion/*" element={<ActividadesInspeccion/> } />
            <Route path="plan_de_estabilizacion/*" element={<PlanEstabilizacion/> } />
            <Route path="gestion_de_la_educacion/*" element={<GestionEducacion/> } />
            <Route  path="auditorias/*" element = {<Auditoria/>} /> 
            <Route  path="extension/*" element = {<Extension/>} /> 
            <Route  path="plan_institucion_academica/*" element = {<PIC/>} /> 
            <Route  path="internacionalizacion/*" element = {<Internacionalizacion/>} /> 
            <Route  path="planeacion_educacion/*" element = {<PlaneacionEducacion/>} /> 
            <Route  path="gestionar_solicitudes/*" element = {<GestionarSolicitudes/>} /> 
            <Route  path="ofertas_academicas/*" element = {<OfertaAcademica/>} />
            <Route  path="oferta_academica/*" element = {<OfertaAcademica/>} />
            <Route  path="planeacion_academica/*" element = {<PlaneacionAcademica />} />
            <Route  path="bienestar/*" element={<Bienestar/>}/>
            <Route  path="convocatorias/*" element = {<Convocatoria /> }></Route>
            <Route  path="seguridad/*" element = {<Seguridad />} />
            <Route  path="admision/*" element={<Admision />} />
            <Route  path="aspirante/*" element = {<Aspirante />}/>
            <Route  path="docentes/*" element = {<Docente/>} /> 
            <Route  path="registro_y_control/*" element = {<Registro/>} />
            <Route  path="coordinador/*" element = {<Coordinador/>} /> 
            <Route  path='coordinador_academico/*' element={<CoordinadorAcademico/>}/>
            <Route  path="estudiante/*" key={'estudiante'}  element = {<Estudiante ventanas={[]}></Estudiante>} />
            <Route  path="admision_y_matricula/*" element={<AdmisionMatricula />} />
            <Route  path="evaluaciones/*" element={<Evaluaciones/>} />
            <Route  path="financiero/*" element={<Financiero/>} />
            <Route  path="egresado/*" element = {<Egresado/>}></Route> 
            <Route  path="perfiles/*" element = {<Usuarios></Usuarios>}></Route>
            <Route  path="gestion_docentes/*" element = {<GestionDocentes/>}></Route>
            <Route  path='reportes/*' element={<Reportes/>}/>
            <Route  path='calendario_academico/*' element={<Calendario/>}/>
            <Route  path="actividades_tercerizadas/*" element={<ActividadesTercerizadas/>}/>
            <Route  path="oferta_actividadtercerizada/*" element={<ActividadesTerceros/>}/>
            <Route  path="apoyo_gestion/*" element={<ApoyoGestion/>} />
            <Route  path="gestion_de_egresados/*" element={<GestionEgresados/>}/>
            <Route  path='alertas/*' element={<Alertas/>}/>
            {/* <Route  path='seguridad_aerea/*' element={<SeguridadAerea/>}/> */}
            <Route  path='investigacion_de_accidentes/*' element={<InvestigacionAccidentes/>}/>
            <Route  path="grados_y_certificados/*" element = {<GradosCertificados/>}></Route>
            <Route  path="autoservicio_grado/*" element = {<AutoservicioGrado/>}></Route>
            <Route path="empresa/*" element={<Empresas />}></Route> 
            <Route  path="hoja_de_vida/hoja_de_vida" element = {<ActualizarDatosAspirante Docente={true}/>}></Route>

            <Route path="aperturas_academicas/aperturas" element={<AperturaContainer/>} />
            <Route path="matricula/estudiante" element={<Estudiante />} />
            <Route path="plan_de_estudio_y_asignaturas/procesos" element={<ProcesosContainer />} />       
            
            <Route path="evaluacion_academica/reporte_notas" element = {<NotFound404></NotFound404>}></Route>
            <Route path="evaluacion_academica/registro_estudiante" element = {<Estudiante></Estudiante>}></Route>
            <Route path="bienestar/alertas" element = {<NotFound404></NotFound404>}></Route>
            <Route path="*" key={'****'} element = {<DynamicFormContainer />}></Route>             
            <Route path="Egresados/ofertas_de_empleo" element = {<DynamicFormContainer idForm="63"/>}></Route>
            <Route path="coordinador_academico/alertas_inasistencia" element={<DynamicFormContainer idForm=""/>}></Route>
            <Route path="not-found" element={<NotFound404 />} /></Route>   
            <Route path="*"  element={<Navigate to="/inicio" replace />} />
        </Routes>     
      </Suspense>
      </div>
    </CacheBuster>
  );
}



const NotFound404 = () => <>
  <img src={notfoundimage} alt="404" width={'50%'} className="img-fluid" />
  <h1>No se encontró página</h1>
</>

export default App;


