import React, { useEffect, useState } from 'react';
import { Checkbox } from '../../../../components/componentsForm/checkbox/checkbox';
import { DynamicSearch } from '../../../../components/DynamicForm/DynamicSearch/DynamicSearch';
import { DynamicTable } from '../../../../components/DynamicForm/dynamicTable/DynamicTable';
import { Input } from '../../../../components/input/input';
import { Select } from '../../../../components/select/Select';
import { alerta} from '../../../../helpers/alertas';
import { BASE_URL } from '../../../../services/api/config';
import { FormService } from '../../../../services/api/formService';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Modal, Row, Table } from 'react-bootstrap';
import { LocalStorage } from '../../../../services';
import Swal from 'sweetalert2';

const CursoForm = () => {
    const storage= new LocalStorage()
    const api = new FormService();
    const dimension_actual=storage.getItem('dimension')
    const [dimension, setDimension ] = useState();
    const [tipoEstudio , setTipoEstudio] = useState('');
    const [regional , setRegional] = useState('');
    const [nombre, setNombre ] = useState('');
    const [flagCalificacion, setFlagCalificacion] = useState(false);
    const [poblacionObjetivo, setPoblacionObjetivo] = useState('');
    const [tipoMecanismo, setTipoMecanismo] = useState('');
    const [maximoEstudiantes , setMaximoEstudiantes] = useState(0);
    const [minimoEstudiantes, setMinimoEstudiantes] = useState(0);
    const [justificacion, setJustificacion] = useState('');
    const [estadoCurso, setEstadoCurso] = useState('');
    const [flagRequiereConsentimiento, setFlagRequiereConsentimiento] = useState(false);
    const [areaDeConocimiento, setAreaDeConocimiento] = useState('');
    const [tipoEducacion, setTipoEducacion] = useState('Continuada');
    const [perfilIngreso, setPerfilIngreso] = useState('');
    const [codigo, setCodigo] = useState('');
    const [nivelFormacion, setNivelFormacion] = useState('');
    const [endPoint,setEndPoint]=useState("curso");
    const [ValorCredito,setValorCredito]=useState();
    const endPointLastUrl="curso"
    const [urlExportar, setUrlExportar] = useState(`${BASE_URL}/exportarCursos`)
    const navigation=useNavigate();



    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [isOpenModalCreate, setIsOpenModalCreate] = useState(false);
    const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
    const [isOpenModalUpdate, setIsOpenModalUpdate] = useState(false);
    const [itemSelected, setItemSelected] = useState({});
    const [modalType, setModalType] = useState('');
    const [camposForm, setCamposForm] = useState(null);
    const [IdPais, setIdPais] = useState()
    const [IdDepartamento, setIdDepartamento] = useState()
    const [IdMunicipio, setIdMunicipio] = useState()

    const [pagination, setPagination] = useState(
        {
            current_page:1,
            prev_page_url:null,
            next_page_url:null,
            per_page:0
        }
        )

    //! LOAD_DATA_API */
    useEffect(() => {
        if(dimension_actual=='"true"'){
            setDimension(1)
            setTipoEstudio(2)
            setTipoEducacion('Posgrado')
            setFlagCalificacion(true)
            setNivelFormacion('Programa')

        }

        const fecthData = async () => {
            setLoading(true);
            setLoading(false);
        };
        fecthData();
        fetchCamposFormForFilter()
    }, []);


    //! Evaluar tipo de actividad
    useEffect(() => {
        if(tipoEstudio === "2"||tipoEstudio==="1"||tipoEstudio==="6"){
            setFlagCalificacion(true);
        }else{
            setFlagCalificacion(false);
        }

    }, [tipoEstudio]);

    const fetchCamposFormForFilter = ()=>{
          api.getAll(`xcampos?id_formulario=8`)
        .then((res)=>{
            setCamposForm(res)
  
        })
    }

    //! SUBMIT CREATE OR UPDATE
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (modalType === '/create') {
            createService();
        }
        if (modalType === '/update') {
            updateService();
        }
        setLoading(false);
    };

    const cleanFormValues = () => {
        setDimension('');
        setTipoEstudio('');
        setRegional('')
        setNombre('');
        setFlagCalificacion(false);
        setPoblacionObjetivo('');
        setTipoMecanismo('');
        setMaximoEstudiantes(0);
        setMinimoEstudiantes(0);
        setJustificacion('');
        setEstadoCurso('');
        setFlagRequiereConsentimiento(false);
        setAreaDeConocimiento('');
        setPerfilIngreso('');


    };
    /* console.log(dataTable); */

    const cleanAlgunosValores = () => {
        setTipoEducacion(null);
        setNivelFormacion(null);
    }


    //! CLICK EVENTS
    const onCreate = () => {
        cleanFormValues();
        setIsOpenModalCreate(true);
        setModalType('/create');
       /*  console.log(list); */
    };
    const onUpdate = (item) => {
        setItemSelected(item);
        console.log('update item->', item);
        setDimension(item.IdDimension)
        setTipoEstudio(item.IdTipoEstudio)
        setRegional(item.IdRegional)
        setNombre(item.Nombre)
        setFlagCalificacion(item.FlagCalificacion === '1' ? true : false)
        setPoblacionObjetivo(item.PoblacionObjetiva)
        setTipoMecanismo(item.IdTipoMecanismo)
        setMaximoEstudiantes(item.MaximoEstudiantes)
        setMinimoEstudiantes(item.MinimoEstudiantes)
        setJustificacion(item.Observaciones)
        setEstadoCurso(item.IdTipoEstadoCurso)
        /* setFlagRequiereConsentimiento(item.Concentimientos === '1' ? true : false) */
        setFlagRequiereConsentimiento(false)
        setAreaDeConocimiento(item.IdArea)
        setTipoEducacion('Continuada')
        setNivelFormacion(item.NivelFormacion)
        setPerfilIngreso(item.perfil_ingreso)
        setCodigo(item.Codigo)
        setValorCredito(item.ValorCredito)

        setModalType('/update');
        setIsOpenModalUpdate(true);
    };

    //!CLOSE ALL MODALES
    const handleCloseModal = () => {
        setIsOpenModalCreate(false);
        setIsOpenModalUpdate(false);
        setIsOpenModalDelete(false);
    };

    //! SERVICE CREATE
    const createService = async () => {
        const checkCalificacion = flagCalificacion ? '1' : '0';
        const checkConsentimiento = flagRequiereConsentimiento ? '1' : '0';

        /* flagRequiereConsentimiento === true ? setFlagRequiereConsentimiento(1) :  setFlagRequiereConsentimiento(0); */
        setFlagRequiereConsentimiento(0);
        flagCalificacion === true ? setFlagCalificacion(1) : setFlagCalificacion(0);
         const data={
            IdDimension: dimension,
            IdTipoEstudio: tipoEstudio,
            Nombre: nombre,
            FlagCalificacion: checkCalificacion,
            PoblacionObjetiva: poblacionObjetivo,
            Estado: 1,
            Codigo: codigo,
            MaximoEstudiantes: maximoEstudiantes,
            MinimoEstudiantes: minimoEstudiantes,
            Observaciones: justificacion,
            IdTipoEstadoCurso: estadoCurso,
            Concentimientos: checkConsentimiento,
            IdArea: areaDeConocimiento,
            NivelFormacion: nivelFormacion,
            TipoEducacion: tipoEducacion,
            IdTipoMecanismo: tipoMecanismo,
            perfil_ingreso: perfilIngreso,
            ValorCredito:ValorCredito,
            IdRegional: regional
        }

            
        const response = await api.create(endPointLastUrl, data);
        if (response.success){
            setIsOpenModalCreate(false);
            alerta( 'Se ha creado con éxito');
            cleanFormValues();
            setEndPoint(`${endPointLastUrl}?i=${Math.random()}`)
        }else{
            alerta("No se pudo crear el curso")
        }
    };

    //! SERVICE DELETE
    const deleteRegister = async (e) => {

        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#009688',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await api.delete(endPointLastUrl, {Estado:0},e.IdCurso);

                if(response.respuesta === "exito"){
                    setIsOpenModalCreate(false);
                    handleCloseModal();
                    alerta( 'Se ha eliminado con Exito' );
                    cleanFormValues();
                    setItemSelected({});
                    setEndPoint(`${endPointLastUrl}?i=${Math.random()}`)
                }else{
                    handleCloseModal();
                    alerta( 'No se ha podido eliminar', 'error');
                }

            }
        });
    };

    //! SERVICE UPDATE
    const updateService = async () => { 
        const checkCalificacion = flagCalificacion ? '1' : '0';
        const checkConsentimiento = flagRequiereConsentimiento ? '1' : '0';
        /* flagRequiereConsentimiento === true ? setFlagRequiereConsentimiento(1) :  setFlagRequiereConsentimiento(0); */
        setFlagRequiereConsentimiento(0);
        flagCalificacion === true ? setFlagCalificacion(1) : setFlagCalificacion(0);
        const data={
            IdDimension: dimension,
            IdTipoEstudio: tipoEstudio,
            Nombre: nombre,
            FlagCalificacion: checkCalificacion,
            PoblacionObjetiva: poblacionObjetivo,
            Estado: 1,
            Codigo: codigo,
            MaximoEstudiantes: maximoEstudiantes,
            MinimoEstudiantes: minimoEstudiantes,
            Observaciones: justificacion,
            IdTipoEstadoCurso: estadoCurso,
            Concentimientos: checkConsentimiento,
            IdArea: areaDeConocimiento,
            NivelFormacion: nivelFormacion,
            TipoEducacion: tipoEducacion,
            IdTipoMecanismo: tipoMecanismo,
            ValorCredito:ValorCredito,
        }
        const response = await api.update( endPointLastUrl,data,itemSelected.IdCurso );
        if (response.respuesta === 'exito') {
            setIsOpenModalUpdate(false);
            alerta( 'Se ha actualizado con Exito', );
            setItemSelected({});
            setEndPoint(`${endPointLastUrl}?i=${Math.random()}`)
        }else{
            alerta('Hubo un error')
        }
    };

    const onSearch = (urlsParams) => {
        // fetchDataTable('curso'+urlsParams)
        setEndPoint(`curso${urlsParams}`)
        setUrlExportar(urlExportar+urlsParams)
    }

    //!FORM
    const Form = () => {
        return (
            <>
            <Modal.Header closeButton>
                <Modal.Title>
                    Actividad Académica
                    {/* {dimension_actual=='"true"'
                        ? 'Programa'
                        : 'Actividad Académica'
                    } */}
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
               <Modal.Body>
                    <div className="container">
                        <div className="row">
                            {dimension_actual!='"true"' &&
                             <>
                            <Select
                                id="IdDimension"
                                name="IdDimension"
                                onChange={setDimension}
                                value={dimension}
                                label="Dimensión"
                                required={true}
                                optionValue="IdDimension"
                                optionName="Descripcion"
                                urlOptionsService = {'dimensiones'}
                                
                            /> 
                            <Select
                                id="IdTipoEstudio"
                                name="IdTipoEstudio"
                                onChange={setTipoEstudio}
                                value={tipoEstudio}
                                label="Tipo Actividad Académica"
                                required={true}
                                optionValue="IdTipoEstudio"
                                optionName="Descripcion"
                                urlOptionsService = {'tipoEstudio'}
                            />

                            <Checkbox
                                id='FlagCalificacion'
                                name='FlagCalificacion'
                                checked={flagCalificacion}
                                onChange={setFlagCalificacion} 
                            >
                                Calificable
                            </Checkbox>
                            {/* <Select
                                id="tipoProg"
                                name="tipoProg"
                                onChange={setTipoEducacion}
                                value={tipoEducacion}
                                required={false}
                                label="Tipo Educación"
                                optionValue="tipoProg"
                                optionName="Descripcion"
                                options={
                                    [
                                        { tipoProg: 'Pregrado', Descripcion: 'Pregrado' },
                                        { tipoProg: 'Posgrado', Descripcion: 'Posgrado' },
                                        { tipoProg: 'Continuada', Descripcion: 'Continuada' },
                                    ]
                                }
                            /> */} 
                           
                            {tipoEducacion === 'Pregrado' && 
                            <Select
                                id="tipoPos"
                                name="tipoPos"
                                onChange={setNivelFormacion}
                                value={nivelFormacion}
                                required={true}
                                label="Tipo Pregrado"
                                optionValue="tipoProg"
                                optionName="Descripcion"
                                options={
                                    [
                                        { tipoPos: 'Técnico', Descripcion: 'Técnico' },
                                        { tipoPos: 'Tecnológico', Descripcion: 'Tecnológico' },
                                        { tipoPos: 'Profesional ', Descripcion: 'Profesional ' },
                                    ]
                                }
                            />} 

                            {tipoEducacion === 'Posgrado' && 
                            <Select
                                id="tipoPos"
                                name="tipoPos"
                                onChange={setNivelFormacion}
                                value={nivelFormacion}
                                required={true}
                                label="Tipo Posgrado"
                                optionValue="tipoProg"
                                optionName="Descripcion"
                                options={
                                    [
                                        { tipoPos: 'Maestria', Descripcion: 'Maestria' },
                                        { tipoPos: 'Doctorado', Descripcion: 'Doctorado' },
                                        { tipoPos: 'Especialización', Descripcion: 'Especialización' },
                                    ]
                                }
                            />}
                            </>
                        }
                            <Input
                                id="Nombre"
                                label={dimension_actual=='"true"'?"Nombre del Programa":"Nombre"}
                                type="text"
                                name="Nombre"
                                value={nombre}
                                onChange={setNombre}
                            />
                            <Input
                                id="PoblacionObjetivo"
                                label="Población Objetivo"
                                type="text"
                                name="PoblacionObjetivo"
                                value={poblacionObjetivo}
                                onChange={setPoblacionObjetivo}
                            />

                            <Input
                                id="perfil_ingreso"
                                label="Perfil de Ingreso"
                                type="text"
                                name="perfil_ingreso"
                                value={perfilIngreso}
                                onChange={setPerfilIngreso}
                            />
                            <Input
                                type="text"
                                id="IdTipoMecanismo"
                                name="IdTipoMecanismo"
                                onChange={setCodigo}
                                value={codigo}
                                label={dimension === '1' ? 'Código SNIES' : 'Código'}
                                required={true}
                            /> 
                            <Input
                                id="MaximoEstudiantes"
                                label="Máximo Estudiantes"
                                type="number"
                                name="MaximoEstudiantes"
                                value={maximoEstudiantes}
                                onChange={setMaximoEstudiantes}
                            />
                            <Input
                                id="MininoEstudiantes"
                                label="Mínimo  Estudiantes"
                                type="number"
                                name="MininoEstudiantes"
                                value={minimoEstudiantes}
                                onChange={setMinimoEstudiantes}
                            />
                            <Input
                                id="Observaciones"
                                label="Justificación"
                                type="text"
                                name="Observaciones"
                                value={justificacion}
                                onChange={setJustificacion}
                            />

                            <Select
                                id="IdTipoEstadoCurso"
                                name="IdTipoEstadoCurso"
                                onChange={setEstadoCurso}
                                value={estadoCurso}
                                // label="Estado Curso - Programa"
                                label={dimension_actual=='"true"'? "Estado del Programa" : "Estado de la Acividad Académica"}
                                required={true}
                                optionValue="IdTipoEstadoCurso"
                                optionName="Descripcion"
                                urlOptionsService = {'tipoEstadoCurso'}
                                
                            /> 
                            {/* <Checkbox
                                id='Concentimientos'
                                name='Concentimientos'
                                checked={flagRequiereConsentimiento}
                                onChange={setFlagRequiereConsentimiento} 
                            >
                                Requiere Consentimiento
                            </Checkbox> */}
                            <Select
                                id="IdArea"
                                name="IdArea"
                                onChange={setAreaDeConocimiento}
                                value={areaDeConocimiento}
                                label="Área de Conocimiento"
                                required={true}
                                optionValue="IdArea"
                                optionName="Descripcion"
                                urlOptionsService = {'area'}
                            />
                            {dimension==1&&
                                <>
                                <Input
                                label="Valor del crédito"
                                value={ValorCredito}
                                onChange={setValorCredito}
                            />
                             
                            <Select
                                id="IdPais"
                                name="IdPais"
                                onChange={(e) => setIdPais}
                                value={IdPais}
                                label="País"
                                required={true}
                                optionValue="Idpaises"
                                optionName="Descripcion"
                                urlOptionsService = {'paises'}
                            />
                            <Select
                                id="IdDepartamento"
                                name="IdDepartamento"
                                onChange={(e) => setIdDepartamento}
                                value={IdDepartamento}
                                label="País"
                                required={true}
                                optionValue="IdDepartamento"
                                optionName="Descripcion"
                                urlOptionsService = {'departamentos'}
                            />
                            {IdDepartamento &&
                                <Select
                                    id="IdMunicipio"
                                    name="IdMunicipio"
                                    onChange={(e) => setIdMunicipio}
                                    value={IdMunicipio}
                                    label="Ciudad"
                                    required={true}
                                    optionValue="IdMunicipio"
                                    optionName="Descripcion"
                                    urlOptionsService = {`ciudad?IdDepartamento=${IdDepartamento}`}
                                />
                            }
                            </>
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <Button
                        variant='secondary' 
                        onClick={handleCloseModal}
                    >
                        Cerrar
                    </Button>

                    {modalType === '/update' && (
                        <Button 
                        type="submit"
                        loading={loading}>
                            Actualizar
                        </Button>
                    )}

                    {modalType === '/create' && (
                        <Button
                        type="submit"
                        loading={loading}>
                            Crear
                        </Button>
                    )}
                </Modal.Footer>
                </form>
            </>
        );
    };
    const redirigir=(e)=>{
        navigation("plan_estudio",{state:{curso:e}})
    }
    return (
        // MAIN CONTAINER
        <>
        <Card>
            <Card.Title>
                <Row>
                    <Col>
                        {
                            dimension_actual=='"true"'? "Programa Académico ES" : "Actividades Académicas"
                        }
                    </Col>
                    <Col>
                        <div style={{textAlign:"right"}}>
                        <Button
                            onClick={onCreate}
                        >
                            Crear
                        </Button>
                        </div>
                    </Col>
                </Row>
            </Card.Title>
        </Card>
            {camposForm &&
                <DynamicSearch onSearch={onSearch} camposForm={camposForm} />
            }
            {/* formulario para buscar */}
            {/* <div>
                <Row>
                    <Col>
                        <Input
                            label="Nombre"
                        />
                    </Col>
                    <Col>
                        <Input
                            label={dimension_actual=='"true"'?"Código SNIES":"Código"}
                        />
                    </Col>
                    <Col
                        sm="2"
                        style={{
                            display:'flex',
                            alignItems:'end'
                        }}
                    >
                        <Button >
                            Buscar
                        </Button>
                    </Col>
                </Row>
            </div> */}

            {dimension_actual=='"true"'?
                 <DynamicTable 
                    titleColumn={[ 'Id', 'Código SNIES', 'Nombre del Programa','Acciones']}
                    attributes={['Codigo','Nombre']}
                    endPoint={endPoint}
                    labelOptionClick={"Planes de Estudio"}
                    handleOptionClick={(e)=>redirigir(e)}
                    actualizarExterno={(e)=>onUpdate(e)}
                    elimimarExterno={(e)=>{deleteRegister(e)}}
                />
             :
             <DynamicTable 
                titleColumn={[ 'Id', 'Código', 'Nombre de la Actividad Académica','Acciones']}
                attributes={['Codigo','Nombre']}
                endPoint={endPoint}
                labelOptionClick={"Planes de Estudio"}
                handleOptionClick={(e)=>redirigir(e)}
                actualizarExterno={(e)=>onUpdate(e)}
                elimimarExterno={(e)=>{deleteRegister(e)}}
                exportar={urlExportar}
            />
            }
           
            <Modal 
                show={isOpenModalCreate}
                onHide={()=>handleCloseModal()}
            >
                {Form()}
            </Modal>
            <Modal 
                show={isOpenModalUpdate}
                onHide={()=>handleCloseModal()}
            >
                {Form()}
            </Modal>
        </>
    );
};

export { CursoForm };
