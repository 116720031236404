import React, { useRef } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Overlay, Popover, Row, Toast } from 'react-bootstrap'
import { BsFillAwardFill, BsFillChatRightTextFill, BsFillKeyFill, BsFillLayersFill, BsFillPencilFill, BsFillPipFill } from 'react-icons/bs'
import { BiBookAlt,BiBookOpen } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

export default function Menu({usuario}) {
    const navigate=useNavigate();
    const [show,setShow]=useState();
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

  return (
    <div style={{textAlign:"center"}} ref={ref}>
            
            <Row>
                <Col>
                    <Card 
                        onClick={()=>{navigate("actualizar_datos",{state:{usuario:usuario}})}}
                        style={{cursor:"pointer"}}
                    >
                        <BsFillPencilFill style={{margin:"0 auto"}}/>
                        <p>Actualizar Datos</p>
                    </Card>
                </Col>
                <Col>
                    <Card
                        onClick={()=>{navigate("cambiar_clave",{state:{usuario:usuario}})}}
                        style={{cursor:"pointer"}}
                    >
                        <BsFillKeyFill style={{margin:"0 auto"}}/>
                        <p>Cambiar Contraseña</p>
                    </Card>
                </Col>
                <Col>
                    <Card
                        onClick={()=>{navigate("ofertas_de_empleo",{state:{usuario:usuario}})}}
                        style={{cursor:"pointer"}}
                    >
                        <BsFillPipFill style={{margin:"0 auto"}}/>
                        <p>Ofertas de Empleo</p>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card
                        onClick={()=>{navigate("solicitudes",{state:{usuario:usuario}})}}
                        style={{cursor:"pointer"}}
                    >
                        <BsFillChatRightTextFill style={{margin:"0 auto"}}/>
                        <p>Autoservicio Académico</p>
                    </Card>
                </Col>
                <Col>
                    <Card
                        onClick={(event)=>{
                            setShow(true)
                            setTarget(event.target);
                        }}
                        style={{cursor:"pointer"}}
                    >
                        <BsFillLayersFill style={{margin:"0 auto"}}/>
                        <p>Historial Académico</p>
                    </Card>
                    <Overlay
                        show={show}
                        target={target}
                        placement="bottom"
                        container={ref}
                        containerPadding={20}
                        onHide={()=>setShow()}
                        rootClose={true}
                    >
                        <Popover id="popover-contained">
                        {/* <Popover.Header as="h3">Popover bottom</Popover.Header> */}
                        <Popover.Body>
                            <Button
                                onClick={()=>{navigate("historial_continuada",{state:{usuario:usuario}})}}
                            >
                                Continuada
                            </Button>
                            {' '}
                            <Button
                                onClick={()=>{navigate("historial_IES",{state:{usuario:usuario}})}}
                            >
                                Superior
                            </Button>
                        </Popover.Body>
                        </Popover>
                    </Overlay>
                </Col>
                <Col>
                    <Card
                        onClick={()=>{navigate("reconocimientos",{state:{usuario:usuario}})}}
                        style={{cursor:"pointer"}}
                    >
                        <BsFillAwardFill style={{margin:"0 auto"}}/>
                        <p>Reconocimientos</p>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card
                        onClick={()=>{navigate("convocatorias_grado",{state:{usuario:usuario}})}}
                        style={{cursor:"pointer"}}
                    >
                        <BiBookAlt style={{margin:"0 auto"}}/>
                        <p>Autoservicio Grados</p>
                    </Card>
                </Col>
                <Col>
                    <Card
                        onClick={()=>{navigate("actividades_bienestar",{state:{usuario:usuario}})}}
                        style={{cursor:"pointer"}}
                    >
                        <BiBookOpen style={{margin:"0 auto"}}/>
                        <p>Actividades Bienestar</p>
                    </Card>
                </Col>
            </Row>
       </div>
  )
}
