
// export const BASE_SERVIDOR =  `https://halconerp.net/servidor-continuada-pruebas/public`
export const BASE_SERVIDOR =  `https://halconerp.net/servidor-continuada/public`
export const BASE_URL =  `${BASE_SERVIDOR}/api`
export const BASE_URL_REPOSITORIO =  `${BASE_SERVIDOR}/img/`
export const BASE_URL_MOODLE =  'https://ceamoodle.halconerp.com/'
export const ACCESS_TOKEN =  'access_token'
export const USUARIO =  'usuario'
export const ID_ROL =  'idRol'
export const DIMENSIONES =  'idRol'
export const MENU =  'menu'
export const plantilla=  'CEA';

// obtener el servidor actual  
export const servidorActual = window.location.href.split('/')[2]
// servidor Enlace
// export const servidorEnlace = "https://iescea2023.halconerp.com"
export const servidorEnlace = "https://iescea.halconerp.com"

