import { Route, Routes } from "react-router-dom"
import { DynamicFormContainer } from "../../../components/DynamicForm/DynamicFormContainer"
import { TipoCuenta } from "../../../components/outlet/tipoCuenta/TipoCuenta"
import { PageNotFound } from "../../../pages/PageNotFound/PageNotFound";
import { Aspirantes } from "./aspirantes/Aspirantes";
import Calendar from "./CalendarioAcademico/Calendar";
import { CalendarioAcademicoContainer } from "./CalendarioAcademico/CalendarioAcademicoContainer";
import { CalendarioAulasContainer } from "./CalendarioAcademico/CalendarioAulasContainer";
import PagoAsignaturas from "./PagoAsignaturas";
import PagoSolicitudes from "./PagoSolicitudes";
import { Conceptos } from "./Conceptos/Conceptos";



function Financiero () {

  return (
    <Routes>
    <Route  path="pagos" element = {<Conceptos/>}></Route>
    <Route  path="calendario_academico" element = {<CalendarioAcademicoContainer />}></Route>
    <Route  path="calendario_academico/aulas" element = {<CalendarioAulasContainer />}></Route>
    <Route  path="calendario_academico/aulas/:id/calendario" element = {<Calendar editable="false"/>}></Route>
    <Route  path="*" element = {<DynamicFormContainer />}></Route>  
    <Route  path="aspirantes" element={<Aspirantes/>}></Route>
    <Route  path="pago_asignaturas" element={<PagoAsignaturas/>}/>
    <Route  path="pago_solicitudes" element={<PagoSolicitudes/>}/>
  </Routes>

  )
}
export { Financiero }