import React, { useEffect } from 'react'

import { Button } from '../../button/button'
import {LoadingSpinner} from '../../LoadingSpinner/LoadingSpinner'
import './Table.css'
const Table = ({
    titleColumn,
    data,
    attributes,
    onDelete,
    onUpdate,
    onThirdBtn,
    titleThirdBtn,
    iconTitleThirdBtn,
    onFourthBtn,
    titleFourthBtn,
    isLoading,
    iconTitleDetails='bi-aspect-ratio',
    titleDetails='Detalles',
    onDetails,
    pagination,
    fetchDataTable
}) => {

    return (
    <>
        {
        isLoading?<LoadingSpinner />:
        <div className='' style={{overflow: "hidden", padding:".41rem"}}>
        <section className='table-wrapper-scroll-y table-scrollbar ' style={{width:'100%'}}>
            <table className="w-100 table table-bordered table-striped mb-0">
                <thead className="text-light" 
                            style={{
                                position: "sticky", 
                                top: "0", 
                                boxShadow: "rgba(0, 0, 0, 0.4) 0px 6px 7px", 
                                borderBottom: "transparent 1px solid",
                                zIndex:10,
                                background:"#005AA6"
                            }} >
                <tr style={{border: "0px solid transparent !important"}}>
                    {
                        titleColumn && (
                            titleColumn.map((title, key) => (
                                <th 
                                    scope="col"
                                    key={ key }
                                >
                                        { title }
                                </th>
                            ))
                        )
                    }
                </tr>
            </thead>
            <tbody style={{border: "0 solid transparent"}}>
                {                    
                     data.map((item,index) => (
                        <tr key={ index } className="cell">
                            <td  scope="row" >{ index + 1 }</td>
                            {
                                !!attributes && attributes.map((attribute, key) => (
                                    <td 
                                        key={ key } >
                                                        { item[attribute] }
                                                    </td>
                                                ))
                                            }
                                            <td>
                                                <div className="dropdown ">
                                                    <button 
                                                        className="btn dropdown-toggle" 
                                                        type="button" id="dropdownMenuButton2" 
                                                        data-bs-toggle="dropdown" 
                                                        aria-expanded="false">
                                                        <i className="bi bi-three-dots-vertical"></i>
                                                    </button>
                                                    <ul 
                                                        className="dropdown-menu dropdown-menu-light  box-menu"
                                                        aria-labelledby="dropdownMenuButton2"
                                                    >
                                                        {
                                                            onUpdate ? <li>
                                                                <div className="dropdown-item box-element">
                                                                    <Button
                                                                        className='m-0 p-0  '
                                                                        onClick={ () => onUpdate(item) }
                                                                    >
                                                                        <>
                                                                            <i className="bi bi-pencil-square"></i>
                                                                            <span className='m-3'>Actualizar</span>
                                                                        </>
                                                                    </Button>
                                                                </div>
                                                            </li> : null
                                                        }
                                                        { 
                                                            titleThirdBtn ? <li>
                                                                <div className="dropdown-item box-element" >
                                                                    <Button 
                                                                        onClick={ () => onThirdBtn(item) }
                                                                        className='m-0 p-0   '
                                                                    >
                                                                        <>  
                                                                            <i 
                                                                                className={ `bi ${iconTitleThirdBtn} ` }>
                                                                            </i>
                                                                            <span className='m-3'>
                                                                                { titleThirdBtn }
                                                                            </span>
                                                                        </>
                                                                    </Button>
                                                                </div>
                                                        
                                                            </li> : null
                                                        }
                                                        {
                                                            onDetails ? <li>
                                                            <div className="dropdown-item box-element" >
                                                                <Button 
                                                                    onClick={ () =>onDetails(item) }
                                                                    className='m-0 p-0'
                                                                > 
                                                                    <>
                                                                        <i className={ `bi ${iconTitleDetails}` }></i>
                                                                        <span className='m-3'>
                                                                            { titleDetails }
                                                                        </span>
                                                                    </>
                                                                </Button>
                                                            </div>
                                                            </li> : null
                                                        }
                                                        <li><hr className="dropdown-divider" /></li>
                                                        {
                                                            onDelete ? <li>
                                                                <div 
                                                                    className="dropdown-item d-flex justify-content-center box-element-button" 
                                                                >
                                                                    <Button 
                                                                        type='danger'
                                                                        onClick={ () => onDelete(item) }
                                                                        className='m-0'
                                                                    >
                                                                        <>
                                                                            <i className="bi bi-trash"></i>
                                                                            <span className='m-2'>Eliminar</span>
                                                                        </>
                                                                    </Button>
                                                                </div>
                                                            </li> : null
                                                        }
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                    </table>
                </section>
                {fetchDataTable && <nav className="d-flex justify-content-center" aria-label="Page navigation dynamicform">
                    <ul className="pagination">
                        <li className={pagination.prev_page_url?"page-item":"page-item disabled"}><div className="page-link" onClick={()=>fetchDataTable(pagination.prev_page_url)} >Anterior</div></li>
                        <li className="page-item"><div className="page-link" >{pagination.current_page?pagination.current_page:1} </div></li>
                        <li className={pagination.next_page_url?"page-item":"page-item disabled"}><div className="page-link" onClick={()=>fetchDataTable(pagination.next_page_url)}  >Siguiente</div></li>
                    </ul>
                    </nav>}
                    <div className='d-flex justify-content-end' style={{color:'gray'}}>Total {pagination?.total}</div>
                </div>
            }
        </>
    )
}

export { Table }