import React from 'react'
import { DynamicFormContainer } from '../../components/DynamicForm/DynamicFormContainer'
import { useNavigate } from 'react-router-dom'

export default function Funcionarios() {
  const navigate = useNavigate()  
  return (
    <DynamicFormContainer 
      idForm="113"
      labelOptionClick={"Funciones"}
      handleOptionClick={(e)=>navigate(`${e.IdFuncionario}/funciones`,{state:{funcionario:e}})}
    />
  )
}
