import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { FormService } from '../../../services/api/formService'
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner'
import { RenderAction } from '../RenderAction'
import '../DynamicForm.css'
import {
    setError,
    setErrorReset,
    setIsCreate,
    setIsOpenForm,
    setIsOpenDelete,
    onCloseModals,
    setItemSelected,
    clearItemSelected,
    setFieldsForm,
    setForm,
} from '../FormSlice'
import { BASE_URL } from '../../../services/api/config'
import { insertParamsViaHistoryForm } from '../../../shared/util/form'
import { useLocation } from 'react-router-dom'
import { Totales } from './Totales'
import { Button, Dropdown, DropdownButton, Pagination, Table } from 'react-bootstrap'
import { BsArrow90DegRight, BsFillPencilFill, BsTrash } from 'react-icons/bs'
import { Exportar } from '../../../modules/reportes/Exportar'
import { alertaSinBoton } from '../../../helpers/alertas'

const DynamicTable = ({
    titleColumn,
    attributes,
    accionesArray, 
    onUpdate = true,
    onDelete = true,
    handleOptionClick,
    labelOptionClick,
    handleOptionClick2,
    labelOptionClick2,
    handleOptionClick3,
    labelOptionClick3,
    handleOptionClick4,
    labelOptionClick4,
    classNameOptionIcon = 'bi bi-option',
    endPoint,
    onActions = true,
    refreshTableToggle,
    exportar=null,
    elimimarExterno=null,
    actualizarExterno=null,
    formato=null,
    busqueda=null,
    indice=true,
    showIndexColumn = true,
    showPagination = true
}) => {
    const error = useSelector((state) => state.form.error)
    const historyForm = useSelector((state) => state.form.historyForm)
    const location = useLocation()
    const [dataTable, setDataTable] = useState([])
    const [loading, setLoading] = useState([])
    const dispatch = useDispatch()

    const [pagination, setPagination] = useState({
        current_page: 1,
        prev_page_url: null,
        next_page_url: null,
        last_page:null,
        numero_last_page:null,
        pagina_inicial:0,
        per_page: 0,
    })
    const urlExportarPic=`${BASE_URL}/exportar/fomatoPIC`
    const urlExportarPic_Ofa=`${BASE_URL}/exportar/fomatoPICOFA`
    const urlExportarOfa=`${BASE_URL}/exportar/fomatoOFA`
    const tipoIngresoPIC='IdTipoIngreso=1'
    const tipoIngresoPICOFA='IdTipoIngreso=2'
    const tipoIngresoOFA='IdTipoIngreso=3'

    const exportarFormato = () => {

        // if(exportar == "cursos/finalizadosRegistro"){
        //     exportar = "?";
        //     for(var radios of formato) {
        //         if (radios.checked) {
        //             if(radios.value === "1") {
        //                 window.location.href= urlExportarPic + exportar + tipoIngresoPIC;
        //             } else if(radios.value === "2") {
        //                 window.location.href= urlExportarPic_Ofa + exportar + tipoIngresoPICOFA;
        //             } else if(radios.value === "3") {
        //                 window.location.href= urlExportarOfa + exportar + tipoIngresoOFA;
        //             }
        //         }
        //     }
        // }else{
            window.location.href= exportar;
        // }
        /* alertaSinBoton("Debe seleccionar el tipo de Ingreso", "warning", "center", 2000);  */
    }

    useEffect(() => { 
        dispatch(setErrorReset())
        const fetchData = async () => {
            await fetchDataTable()
        }
        fetchData()
    }, [endPoint, refreshTableToggle])

    const onDeleteItem = (item) => {
        // eliminar desde el exterior

        if(elimimarExterno){
            elimimarExterno(item)
            return
        }

        dispatch(clearItemSelected())
        dispatch(onCloseModals())
        dispatch(setItemSelected(item))
        dispatch(setIsOpenDelete(true))
    }

    const onUpdateItem = (item) => {
        if(actualizarExterno){
            actualizarExterno(item)
            return
        }

        dispatch(setIsCreate(false))
        dispatch(clearItemSelected())
        dispatch(onCloseModals())
        dispatch(setItemSelected(item))
        dispatch(setIsOpenForm(true))
    }

    const fetchDataTable = async (endpointPagination = null) => {
        const newEndpoint = insertParamsViaHistoryForm(
            endPoint,
            historyForm,
            location.pathname
        )
        try {
            dispatch(setErrorReset())
            setLoading(true)
            const api = new FormService()
            let endpointPag = endpointPagination

            if (endpointPag)
                endpointPag = endpointPagination.replaceAll(BASE_URL + '/', '')
                const data = await api.getAll(endpointPag || newEndpoint)
            if (data?.data) {
                setPagination({
                    current_page: data.current_page,
                    prev_page_url: data.prev_page_url,
                    next_page_url: data.next_page_url,
                    per_page: data.per_page,
                    numero_last_page:data.last_page,
                    pagina_inicial:`${data.path}?page=0`,
                    last_page:`${data.path}?page=${data.last_page}`,
                    endPoint:{endPoint}
                })

                setDataTable(data.data)
            } else setDataTable(data)
            setLoading(false)
        } catch (err) {
            dispatch(setError([...error, err]))
            setLoading(false)
        }
    }
    return (
        <>
            <section style={{ padding: '.41rem', width: '100%' }}>
                <div className='header-table'>
                    <div className="refresh-container">
                        <span onClick={() => fetchDataTable()}>
                            Refrescar
                            <i
                                title="refrescar tabla"
                                className="bi bi-arrow-clockwise m-1"
                            ></i>
                        </span>
                    </div>
                    {exportar&&                
                        <div className="refresh-container">
                            <a onClick={exportarFormato}>
                                Exportar
                                <i
                                    title="Exportar"
                                    className="bi bi-arrow-down"
                                ></i>
                            </a>
                        </div>
                    }
                </div>
                <Table striped >
                    <thead>
                        <tr>
                            {titleColumn && titleColumn.map((title, key) => 
                                <th key={key}> {title} </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {loading?
                            <tr className="">
                                <td colSpan={'100%'}>
                                    <LoadingSpinner />
                                </td>
                            </tr>
                        : (!!dataTable && dataTable.map((item, index) => (
                                <tr key={index}>
                                        {showIndexColumn && <td scope="row">{index + 1}</td>}
                                    {!!attributes && attributes.map((attribute, key)=>
                                            <td key={key}> {item[attribute]} </td>
                                    )}
                                    {onActions&& 
                                        <td>
                                            <DropdownButton title="">                                                
                                                {!!accionesArray && accionesArray.map( (accion, index) => 
                                                    <RenderAction
                                                        key={index}
                                                        accion={ accion }
                                                        item={item}
                                                        index={ index }
                                                        onDeleteItem={ onDeleteItem }
                                                        onUpdateItem={ onUpdateItem }
                                                    />
                                                )}                                                
                                                {handleOptionClick&&
                                                    <Dropdown.Item
                                                        onClick={()=>handleOptionClick(item)}
                                                    >
                                                        <BsArrow90DegRight/>
                                                    {labelOptionClick ||'Label indefinida'}
                                                    </Dropdown.Item>
                                                }
                                                {handleOptionClick2&& 
                                                    <Dropdown.Item
                                                        onClick={()=>handleOptionClick2(item)}
                                                    >
                                                        <BsArrow90DegRight/>
                                                    {labelOptionClick2 ||'Label indefinida'}
                                                    </Dropdown.Item>
                                                }
                                                {handleOptionClick3&& 
                                                    <Dropdown.Item
                                                        onClick={()=>handleOptionClick3(item)}
                                                    >
                                                        <BsArrow90DegRight/>
                                                    {labelOptionClick3 ||'Label indefinida'}
                                                    </Dropdown.Item>
                                                }
                                                {handleOptionClick4&& 
                                                    <Dropdown.Item
                                                        onClick={()=>handleOptionClick4(item)}
                                                    >
                                                        <BsArrow90DegRight/>
                                                    {labelOptionClick4 ||'Label indefinida'}
                                                    </Dropdown.Item>
                                                }
                                                {onUpdate&&
                                                    <Dropdown.Item
                                                        onClick={()=>onUpdateItem(item)}
                                                    >
                                                        <BsFillPencilFill/>{' '}
                                                        Actualizar
                                                    </Dropdown.Item>
                                                }
                                                {onDelete&&
                                                    <Dropdown.Item 
                                                        onClick={()=>onDeleteItem(item)}
                                                    >
                                                        <BsTrash/>{' '}
                                                        Eliminar
                                                    </Dropdown.Item>
                                                }                                                
                                            </DropdownButton>
                                        </td>
                                    }
                                </tr>
                            ))
                        )}
                    </tbody>
                </Table>
                {showPagination && (
                <div style={{textAlign:"center"}}>
                <Pagination>
                    <Pagination.First
                        onClick={()=>fetchDataTable(`${pagination.pagina_inicial}&${busqueda?busqueda.slice(1):""}`)}
                        disabled={pagination.current_page ==1 && true} 
                    />
                    <Pagination.Prev 
                        onClick={()=>fetchDataTable(`${pagination.prev_page_url}&${busqueda?busqueda.slice(1):""}`)}
                        disabled={pagination.current_page ==1 && true} 
                    />
                    <Pagination.Item>{pagination.current_page
                                ? pagination.current_page 
                                : 1}
                                {' '}
                                de 
                                {' '}
                                {pagination.numero_last_page?pagination.numero_last_page:1}
                    </Pagination.Item>

                    <Pagination.Next 
                        onClick={()=>fetchDataTable(`${pagination.next_page_url}&${busqueda?busqueda.slice(1):""}`)}
                        disabled={pagination.current_page==pagination.numero_last_page&&true}
                    />
                    <Pagination.Last 
                        onClick={()=>fetchDataTable(`${pagination.last_page}&${busqueda?busqueda.slice(1):""}`)}
                        disabled={pagination.current_page==pagination.numero_last_page&&true}
                    />
                </Pagination>
                </div>
                )}
                {/* <Totales /> */}
            </section>
        </>
    )
}

DynamicTable.propTypes = {
    titleColumn: PropTypes.arrayOf(PropTypes.string),
    topHeadTitle: PropTypes.oneOfType([PropTypes.string]),
    attributes: PropTypes.arrayOf(PropTypes.string),
    accionesArray: PropTypes.arrayOf(
        PropTypes.exact({
            nombre: PropTypes.string,
            render: PropTypes.string,
            endpoint: PropTypes.string,
            id: PropTypes.string,
        })
    ),
    onUpdate: PropTypes.bool,
    onDelete: PropTypes.bool,
    onActions: PropTypes.bool,
    handleOptionClick: PropTypes.func,
    labelOptionClick: PropTypes.string,
    classNameOptionIcon: PropTypes.string,
    endPoint: PropTypes.string,
    refreshTableToggle: PropTypes.bool,
}

export { DynamicTable }
