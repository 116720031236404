import React, { useEffect, useState } from 'react';

import { TextArea } from '../../../../components/componentsForm/textarea/textarea';
import { ButtonsContainer } from '../../../../components/componentsForm/buttonsContainer/buttonsContainer';
import { LeftButtonContainer } from '../../../../components/componentsForm/leftButtonContainer/leftButtonContainer';
import { RightButtonContainer } from '../../../../components/componentsForm/rightButtonContainer/rightButtonContainer';
import { FormContainer } from '../../../../components/componentsForm/formContainer/FormContainer';
import { AddButton } from '../../../../components/componentsForm/addButton/addButton';

import ApiDashboard from '../../../../services/api/dashboard';
import { alertaSinBoton, alertaTimer } from '../../../../helpers/alertas';
import { Modal } from '../../../../components/modal/Modal';
import { Table } from '../../../../components/componentsForm/table/Table';
import { Select } from '../../../../components/select/Select';
import { Input } from '../../../../components/input/input';
import { BASE_URL } from '../../../../services/api/config';
import { FormService } from '../../../../services/api/formService';
import {ID_FORM} from '../../../../constants/idForm'
import { DynamicSearch } from '../../../../components/DynamicForm/DynamicSearch/DynamicSearch';
import { useNavigate } from 'react-router-dom';
import DowloadFile from '../../../../components/DowloadFile/DowloadFile';
import DowloadFileText from '../../../../components/DowloadFileText/DowloadFileText';
import { BsArrowReturnRight,BsFillFilePdfFill,BsFillFileExcelFill } from 'react-icons/bs';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { LocalStorage } from '../../../../services';
// modal type : create-delete-update
const DIMENSION={
    IES:'1',
    CIA:'2',
    TRAINAIR:'3',
    CEA:'4',
    ASTC:'5',
    CFBA:'6'
}

const AsignaturaFormContainer = () => {
    const [description, setDescription] = useState('');
    const [codigo, setCodigo] = useState('');
    const [IdDimension,setIdDimension] = useState(1)
    const [IdNucleoConocimiento,setIdNucleoConocimiento] = useState('')
    const [IdTipoAsignatura,setIdTipoAsignatura] = useState('')
    const [IdClase, setIdClase] = useState('');
    const api = new ApiDashboard();
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [isOpenModalCreate, setIsOpenModalCreate] = useState(false);
    const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
    const [isOpenModalUpdate, setIsOpenModalUpdate] = useState(false);
    const [itemSelected, setItemSelected] = useState({});
    const [camposForm, setCamposForm] = useState(null);
    const [dataTable, setDataTable] = useState([]);
    const [modalType, setModalType] = useState('');

    const storage=new LocalStorage();
    const dimension=storage.getItem("dimension");
    const urlasignaturas = dimension=='"true"'?
                            `${BASE_URL}/exportarAsignaturas?IdDimension=${DIMENSION.IES}`   
                            :
                            `${BASE_URL}/exportarAsignaturas?IdDimension=${DIMENSION.CIA}`
    const [pagination, setPagination] = useState(
        {
            current_page:1,
            prev_page_url:null,
            next_page_url:null,
            per_page:0
        }
        )
    const endPointLastUrl = 'asignatura'; /// url for service

    const [list, setList] = useState([]);

    const fetchCamposFormForFilter = ()=>{
        
            const api = new FormService()
            api.getAll(`xcampos?id_formulario=${ID_FORM.ASIGNATURAS}`)
            .then((res)=>{
                setCamposForm(res)
      
            })
          
    }
    const Navigate=useNavigate()
    //! LOAD_DATA_API */
    useEffect(() => {
        

        const fecthData = async () => {
            setLoading(true);
            await fetchDataTable();
            //fecthDataSelect();
            setLoading(false);
        };
        fecthData();
        fetchCamposFormForFilter()
    }, []);


    //! SUBMIT CREATE OR UPDATE
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (modalType === '/create') {
            createService();
        }
        if (modalType === '/update') {
            updateService();
        }
        setLoading(false);
    };

    //! VALIDACION DE TOKEN
    const fetchDataTable = async (endpointPagination=null) => {
        try {
            
            let endpointPag = endpointPagination
            if(endpointPag) endpointPag = endpointPagination.replaceAll(BASE_URL+'/','')
            console.log({endpointPag})
            const data = await api.getData(endpointPag || endPointLastUrl);
            if (!!data.data) {
                setDataTable(data.data);
                setPagination({
                    current_page:data.current_page,
                    prev_page_url:data.prev_page_url,
                    next_page_url:data.next_page_url,
                    per_page:data.per_page,
                    total:data.total
                })
            }
            console.log('dataxxx', data);
            if (data?.status === 'Token is Expired') {
                alertaTimer('La sesion ha expirado', 'info', '4000');
                console.log('data exp', data);
            }
        } catch (e) {
            console.log('#Error fetching dataTable');
            //  setDataTable([])
        }
    };

    //! LIMPIAR FORMULARIO
    const cleanFormValues = () => {
        setDescription('');
        setCodigo('')
        setIdDimension('');
        setIdNucleoConocimiento('');
        setIdTipoAsignatura('');
        setIdClase('');

    };
    /* console.log(dataTable); */

    //! CLICK EVENTS
    const onCreate = () => {
        cleanFormValues();
        setIsOpenModalCreate(true);
        setModalType('/create');
       /*  console.log(list); */
    };
    const onDelete = (item) => {
        setItemSelected(item);
        // console.log(item, 'on delete selected:');
        setIsOpenModalDelete(true);
        setModalType('/delete');
    };
    const onUpdate = (item) => {
        setItemSelected(item);
        console.log('update item->', item);
        setDescription(item.Descripcion);
        setCodigo(item.CodigoExt)
        setIdDimension(item.IdDimension);
        setIdNucleoConocimiento(item.IdNucleoConocimiento);
        setIdTipoAsignatura(item.IdTipoAsignatura);
        setIdClase(item.IdClase);
        setModalType('/update');
        setIsOpenModalUpdate(true);
    };

    //!CLOSE ALL MODALES
    const handleCloseModal = () => {
        setIsOpenModalCreate(false);
        setIsOpenModalUpdate(false);
        setIsOpenModalDelete(false);
    };

    //! SERVICE CREATE
    const createService = async () => {
        try {
            const api = new ApiDashboard();
            const data = await api.createData(
                {
                    Descripcion: description,
                    CodigoExt: codigo,
                    IdTipoAsignatura: IdTipoAsignatura,
                    IdDimension : IdDimension,
                    IdNucleoConocimiento: IdNucleoConocimiento.length === 0 ? null : IdNucleoConocimiento,
                    IdClase: IdClase,
                    Estado: 1,
                },
                endPointLastUrl
            );
            console.log('data create', data);

            if (!!data.length) await setDataTable(data);
            if (data?.status === 'Token is Expired') {
                alertaTimer('La sesión ha expirado', 'info', '4000');
                console.log('data exp', data);
            }
            if (data?.respuesta === "error") {
                alertaTimer(data?.mensaje, 'info', '4000');
                console.log('data exp', data);
            }else {
                setIsOpenModalCreate(false);
                alertaSinBoton(
                    'Se ha creado con éxito',
                    'success',
                    'center',
                    '2000'
                );
                fetchDataTable();
                cleanFormValues();
            }
        } catch (e) {}
    };

    //! SERVICE DELETE
    const deleteService = async () => {
        try {
            console.log('datain delete serivce exp', itemSelected);
            const api = new ApiDashboard();
            const data = await api.deleteData(
                {
                    Estado: 0,
                },
                itemSelected.IdAsignatura,
                endPointLastUrl
            );
            // console.log("data response delete",data,itemSelected.IdPaises)
            if (!!data.length) setDataTable(data);
            if (data?.status === 'Token is Expired') {
                alertaTimer('La sesion ha expirado', 'info', '4000');
                // console.log("data exp",data)
            }
            setIsOpenModalCreate(false);
            handleCloseModal();
            alertaSinBoton(
                'Se ha eliminado con Exito',
                'success',
                'center',
                '2000'
            );

            fetchDataTable();
            cleanFormValues();
            setItemSelected({});
        } catch (e) {
            console.log('error delete');
        }
    };

    //! SERVICE UPDATE
    const updateService = async () => {
        try {
            const api = new ApiDashboard();
            const data = await api.updateData(
                {
                    Descripcion: description,
                    IdTipoAsignatura: IdTipoAsignatura,
                    CodigoExt:codigo,
                    IdDimension : IdDimension,
                    IdNucleoConocimiento: IdNucleoConocimiento,
                    IdClase:IdClase
                },
                itemSelected.IdAsignatura,
                endPointLastUrl
            );
            if (!!data.length) setDataTable(data);
            if (data?.status === 'Token is Expired') {
                alertaTimer('La sesion ha expirado', 'info', '4000');
                console.log('data exp', data);
            }
            setIsOpenModalUpdate(false);
            alertaSinBoton(
                'Se ha actualizado con Exito',
                'success',
                'center',
                '2000'
            );
            fetchDataTable();
            setItemSelected({});
        } catch (error) {}
    };
    
    const onSearch = (urlsParams) => {
        fetchDataTable('asignatura'+urlsParams)
    }

    const irPrerrequsitos=()=>{
        Navigate("prerrequisitos")
    }

    //!FORM
    const Form = () => {
        return (
            <>
            
                {/* ***FORM CREATE AND UPDATE**** */}
                <div>
                    <center>
                        <h4>Agregar Asignatura</h4>
                    </center>
                </div>
                <hr />
                <form onSubmit={handleSubmit}>
                    <div className="container">
                        <div className="row">
                            <Input
                                id="floatingTextarea"
                                label="Nombre"
                                type="text"
                                name="description"
                                value={description}
                                onChange={setDescription}
                            />
                             <Input
                                id="floatingTextarea"
                                label="Código"
                                type="text"
                                name="CodigoExt"
                                value={codigo}
                                onChange={setCodigo}
                            />
                            <Select
                                id="IdDimension"
                                name="IdDimension"
                                onChange={setIdDimension}
                                value={IdDimension}
                                label="Dimensiones"
                                required={true}
                                optionValue="IdDimension"
                                optionName="Descripcion"
                                urlOptionsService = {'dimensiones'}
                                
                            />
                            {  IdDimension === '1' ? (
                                <Select
                                id="IdNucleoConocimiento"
                                name="IdNucleoConocimiento"
                                onChange={setIdNucleoConocimiento}
                                value={IdNucleoConocimiento}
                                label="Núcleo de Conocimiento"
                                required={true}
                                optionValue="IdNucleoConocimiento"
                                optionName="Nombre"
                                urlOptionsService = {'nucleosConocimiento'}
                                />) : null
                            }
                            <Select
                                id="IdTipoAsignatura"
                                name="IdTipoAsignatura"
                                onChange={setIdTipoAsignatura}
                                value={IdTipoAsignatura}
                                label="Tipo Asignatura"
                                required={true}
                                optionValue="IdTipoAsignatura"
                                optionName="Descripcion"
                                urlOptionsService = {'tipoAsignatura'}
                                
                            />
                            
                            {IdDimension===DIMENSION.IES?(
                                <Select
                                id="Clase"
                                name="Clase"
                                onChange={setIdClase}
                                value={IdClase}
                                label="Clase"
                                required={true}
                                optionValue="IdClase"
                                optionName="Descripcion"
                                options={
                                    [
                                        { IdClase: 3, Descripcion: 'Básica' },
                                        { IdClase: 2, Descripcion: 'Específica' },
                                    ]
                                }
                            />

                            )
                        :
                        <Select
                                id="Clase"
                                name="Clase"
                                onChange={setIdClase}
                                value={IdClase}
                                label="Clase"
                                required={true}
                                optionValue="IdClase"
                                optionName="Descripcion"
                                options={
                                    [
                                        { IdClase: 1, Descripcion: 'Transversal' },
                                        { IdClase: 2, Descripcion: 'Especifica' },
                                    ]
                                }
                            />
                        }                            
                        </div>
                    </div>
                    <ButtonsContainer>
                        <LeftButtonContainer>
                            <Button type="back" onClick={handleCloseModal}>
                                Cerrar
                            </Button>
                        </LeftButtonContainer>
                        <RightButtonContainer>
                            {modalType === '/update' && (
                                <Button type="send" loading={loading}>
                                    Actualizar
                                </Button>
                            )}

                            {modalType === '/create' && (
                                <Button type="send" loading={loading}>
                                    Crear
                                </Button>
                            )}
                        </RightButtonContainer>
                    </ButtonsContainer>
                </form>
            </>
        );
    };

    return (
        // MAIN CONTAINER
        <FormContainer progress="45" style={{ width: '60%' }}>
            {/* ***CREATE BUTTON**** */}
            {/* <AddButton
                buttonTitle=""
                createModal={onCreate}
            /> */}
            <Card>
                <Row>
                    <Col >
                        <Card.Title>Agregar Asignatura</Card.Title>
                    </Col>
                    <Col
                        style={{textAlign:"right"}}
                    >
                    <Button
                        style={{margin:2}}
                        variant="primary"
                        onClick={() => document.querySelector('.exportar').click()}
                    >
                        <DowloadFileText
                        nameFile={"Asignaturas.xlsx"}
                        name={"Exportar.xlsx"}
                        colortext={"#ffffff"}
                        icon={<BsFillFilePdfFill />}
                        classname="exportar"
                        endPoint={`${urlasignaturas}`}
                    />
                    </Button>
                    <Button
                        onClick={onCreate}
                        style={{marginRight:5}}
                    >
                        Agregar Asignatura
                    </Button>
                    {/* <Button
                        href={urlasignaturas}
                    >
                    Exportar Asignatura
                    </Button> */}
    
                    </Col>
                </Row>
            </Card>
            {
                camposForm &&
            <DynamicSearch onSearch={onSearch} camposForm={camposForm} />
            }            {/* TABLE */}
            <Table
                titleColumn={[
                    'Id',
                    'Código',
                    'Nombre de la Asignatura',                    
                    'Tipo Asignatura',
                    'Acción',
                ]}
                data={dataTable}
                attributes={['CodigoExt','Descripcion', 'TipoAsignatura']} //codigo
                onDelete={onDelete}
                onUpdate={onUpdate}
                pagination={pagination}
                fetchDataTable={fetchDataTable}
                // titleDetails={'Prerrequisitos'}
                // onDetails={()=>irPrerrequsitos()}

            ></Table>

            {/* ****** MODALES ******  */}

            <Modal isOpen={isOpenModalCreate}>{Form()}</Modal>
            <Modal isOpen={isOpenModalUpdate}>{Form()}</Modal>

            <Modal isOpen={isOpenModalDelete}>
                <h1 style={{ marginBottom: '4rem' }}>
                    Estas seguro que deseas eliminar "
                    {itemSelected.Descripcion || ''}" ?{' '}
                </h1>
                <button onClick={deleteService} className="btn btn-danger">
                    Si
                </button>
                <button
                    style={{ marginLeft: '2rem' }}
                    onClick={handleCloseModal}
                    className="btn btn-secondary"
                >
                    No
                </button>
            </Modal>
        </FormContainer>
    );
};

export { AsignaturaFormContainer };
