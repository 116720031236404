import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import './HeaderHome.css';
import { LocalStorage } from '../../services';

const HeaderHome = ({ type }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [labelButton, setLabelButton] = useState('');
    const Logo="/img/logo_cea.jpg"
    const LogoSAA="/img/logo_aeronautica.png"


    window.onresize = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        if (width > 768) setIsOpen(false);
        const ls = new LocalStorage();
        const usuario = ls.getAsJsonItem('usuario');
        usuario?.result === 'exito'
            ? setLabelButton('Ir al Dashboard')
            : setLabelButton('Iniciar sesión');
    }, [width]);

    return (
        <header >
            <nav className="headerHome" >
                <figure className="headerHome__logo" 
                    style={{
                        float:'left',
                        marginTop:'-10px',
                        marginBottom:'-10px',
                    }}
                >
                    <img src={Logo} width="160" alt="Logo aeronautica" />
                </figure>
                {/* <div id='logo-header-incio'>
                    <div>Centro de Estudios Aeronáuticos </div>
                    <div style={{textAlign: "center"}}>Institución Universitaria</div>
                </div>   */}

                <div className="link_portafolio">
                    <Link className="text-dark" to="../oferta">
                        <i class="bi bi-book me-2"></i>Portafolio Académico
                    </Link>
                </div>
            </nav>
        </header>
    );
};

export { HeaderHome };
