import React, { useEffect, useState } from 'react'
import { SignUp } from './SignUp'
import { useForm } from '../../hooks/useForm'
import { FormService } from '../../services/api/formService'
import { alerta, alertaSinBoton } from '../../helpers/alertas'
import { useNavigate } from 'react-router-dom'

const SignUpContainer = () => {

    const [list, setList] = useState({})
    const [noMatch, setNoMatch] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState('')
    const navigate = useNavigate()
    const {
        onCreate,
        onDelete,
        onUpdate,
        allData,
        data,
        isOpenForm,
        isOpenDelete,
        isCreate,
        handleSubmit,
        handleChange,
        handleClickDelete,
        handleClickAdd,
        deleteTuple,
        loading,
        setData,
        setLoading,
        loadAllData,
        setIsCreate,
    } = useForm(
        null,
        'IdUsuario',
        null
    )

    const [checkMayorEdad, setCheckMayorEdad] = useState(false)
    const [departamentos,setDepartamentos]=useState([])
    const [ciudades,setCiudades]=useState([]);

    const fecthDataSelect = async () => {
        const formService = new FormService()
        const tipoIdentidad = await formService.getAll('tipoIdentidad');
        const estadoCivil = await formService.getAll('estadoCivil');
        const estracto = await formService.getAll('estracto');
        const sexoBiologico = await formService.getAll('sexoBiologico');
        const paises = await formService.getAll('paises');
        const grupoEtnico = await formService.getAll('grupoEtnico');
        const tiposDiscapacidad = await formService.getAll('tiposDiscapacidad');
        const RH = await formService.getAll('RH');
        const comunidadesIndigenas = await formService.getAll('comunidadesIndigenas');
        const comunidadesNegras = await formService.getAll('comunidadesNegras');

        setList({
            tipoIdentidad,
            estadoCivil,
            estracto,
            sexoBiologico,
            paises,
            grupoEtnico,
            tiposDiscapacidad,
            RH,
            comunidadesIndigenas,
            comunidadesNegras,
            departamentos,
            ciudades
        });
    };

    useEffect(() => {
        const fecthData = async () => {
            setLoading(true);
            await fecthDataSelect();
            setLoading(false);
        };
        fecthData();
    }, []);

    const NumText = (string) => {//solo letras y numeros
        var out = '';
        //Se añaden las letras validas
        var filtro = 'abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZ1234567890-';//Caracteres validos
        
        for (var i=0; i<string.length; i++)
           if (filtro.indexOf(string.charAt(i)) != -1) 
                out += string.charAt(i);

        return out;
    }


    const handleChangeConfirmPassword = (value) => {
        setConfirmPassword(value)
    }

    const isSymmectricPassword = (password, confirmPassword) => {
        return password === confirmPassword;
    }

    const handleSubmitBefore = async (e) => {
        e.preventDefault()


        if (isSymmectricPassword(data.password, confirmPassword)) {    
            if(data.password.length < 6) {
                alertaSinBoton(' La contraseña debe contener minímo 6 caracteres ', 'warning', 'center', 1000);
                return;
            }            
            setIsCreate(true);
            const api=new FormService()
      
            const respuesta=await api.create("register",data)
            
            console.log(respuesta)
           if(respuesta.respuesta=="exito"){
                alertaSinBoton('Usuario creado', 'success', 'center', 1000)
                setTimeout(() => { navigate('/') }, 1000)
           }else if(respuesta.respuesta=="error"){
                alerta(respuesta.mensaje);
           }else{
            console.log(".----")
                if(respuesta.mensaje.email){
                    alertaSinBoton('El correo ya se esta registrado', 'error', 'center', 2000)
                }else if(respuesta.mensaje.NumeroDocumento) {
                    alertaSinBoton('El numero de documento ya esta registrado', 'error', 'center', 2000)
                }else {
                    alertaSinBoton('Fallo al crear usuario', 'error', 'center', 2000)
                }
           }  

        } else {
            setNoMatch(true)
        }
    }

    const handleChangeBafore = async (value, name) => {
        setNoMatch(false)
        if(name === "NumeroDocumento") {
           value = NumText(value)
        }
        
        handleChange(value, name)
    }
    
    const navigate_habeas = () => {
        console.log('navigate_habeas');
    }

    return (
        <>
            <SignUp 
                handleSubmit={ handleSubmitBefore }
                handleChangeConfirmPassword={ handleChangeConfirmPassword }
                confirmPassword={ confirmPassword }
                handleChange={ handleChangeBafore }
                loading={ loading }
                formData={ data }
                list={ list }
                noMatch={ noMatch }
                checkMayorEdad={ checkMayorEdad }
                setCheckMayorEdad={ setCheckMayorEdad }
                navigate_habeas={navigate_habeas}
            />
        </>
    )
}

export { SignUpContainer }